/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */
.text-while p {
  color: #fff !important;
}
/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  min-height: 100.1%;
  background: #f0ece6;
  padding-top: 4.15rem;
  font-family: "baskerville-display-pt", serif;
  font-weight: 400;
}
body.no-scroll {
  overflow-y: hidden !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
blockquote,
th {
  font-family: "baskerville-display-pt", serif;
  font-weight: 400;
  font-weight: 700;
}
h2,
.h2 {
  font-family: "baskerville-urw", serif;
  font-size: 2.5rem;
  letter-spacing: 0.2rem;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 0.8rem;
  margin-bottom: 1rem;
}
h2:after,
.h2:after {
  content: '';
  width: 4.2rem;
  height: 1px;
  background: #edc75e;
  display: block;
  position: absolute;
  bottom: 0;
}
h2.text-center:after,
.h2.text-center:after {
  left: 50%;
  transform: translateX(-50%);
}
h2.text-orange2,
.h2.text-orange2 {
  color: #d7682d !important;
}
h2.text-orange2:after,
.h2.text-orange2:after {
  background: #d7682d !important;
}
.text-center h2:after,
.text-center .h2:after {
  left: 50%;
  transform: translateX(-50%);
}
.text-right h2:after,
.text-right .h2:after {
  right: 6px;
}
.text-left h2:after,
.text-left .h2:after {
  left: 6px;
}
h3,
.h3 {
  font-family: "baskerville-urw", serif;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 0.8rem;
  margin-bottom: 1rem;
  color: #000;
}
h4,
.h4 {
  font-size: 2.5rem;
  font-family: "baskerville-display-pt", serif;
  line-height: 1.4;
}
h5,
.h5 {
  font-family: "nunito-sans", sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
}
h6,
.h6 {
  font-family: "baskerville-display-pt", serif;
  font-size: 1.46rem;
  letter-spacing: 0.0875rem;
}
p {
  font-family: "baskerville-display-pt", serif;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 0.05rem;
}
a {
  cursor: pointer !important;
  transition: 0.3s ease all;
}
:focus,
:visited,
:hover,
:active,
a:focus,
a:visited,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none;
}
ul li {
  font-family: nunito-sans, sans-serif !important;
}
/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */
.bg {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.bg-black {
  background-color: #000;
}
.shadow {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1) !important;
}
.overlay {
  display: none;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}
.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
}
.image-frame {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  padding: 5px;
}
.full-height {
  min-height: 100vh;
}
.full-height-first {
  min-height: calc(100vh - 4.15rem);
}
.w-auto {
  width: auto !important;
}
.center-absolute-x {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.center-absolute-y {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-absolute-xy {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-block-x {
  margin-left: auto !important;
  margin-right: auto !important;
}
.center-flex-y {
/* add class to parent of centred content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center-flex-xy {
/* add class to parent of centred content */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* -------------------------------------------------------------------------------
    Components
   ------------------------------------------------------------------------------- */
.page-container {
  min-height: 100vh;
}
.component {
  position: relative;
}
.component-calls-to-action,
.component-carousel,
.component-content,
.component-form-all,
.component-form-contact,
.component-centered-text,
.component-text-image,
.component-image-text,
.component-slider,
.component-portfolio,
.component-tabs,
.component-gallery,
.component-galleries,
.spaced {
  padding-top: 3em;
  padding-bottom: 3em;
}
.spaced {
  padding-top: 7.5rem;
}
.component-header header {
  min-height: 100vh;
}
/* Carousel & Slider */
.slick-image-bg {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}
.slick-slide {
  padding: 0 1em;
  height: 25vw;
}
.slick-slide > div {
  width: 100%;
  height: 100%;
}
.slick-list {
  margin: 0 -1em;
}
.slick-arrows {
  display: flex;
  flex-direction: row;
}
.slick-arrows .slick-arrow {
  width: 2.4rem;
  height: 2.75rem;
  background: #edc75e;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slick-arrows .slick-arrow:hover {
  color: #231f20;
}
.slick-arrows .slick-arrow.slick-disabled {
  color: #d1b233;
}
.slider .img-fluid {
  display: none;
}
.carousel-item {
  height: 50vw;
  overflow: hidden;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 0.4;
  transition: 0.3s ease opacity;
  z-index: 2;
  height: 513px;
}
.carousel-control-next.slick-disabled,
.carousel-control-prev.slick-disabled,
.carousel-control-next.disabled,
.carousel-control-prev.disabled {
  opacity: 0.2 !important;
  cursor: default;
}
.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: 0.6;
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 1;
}
.carousel-control-next .carousel-control-next-icon,
.carousel-control-prev .carousel-control-next-icon,
.carousel-control-next .carousel-control-prev-icon,
.carousel-control-prev .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
}
.product-gallery .carousel-control-next,
.product-gallery .carousel-control-prev {
  height: 100%;
}
.carousel-inner:after {
  width: 100%;
  height: 200px;
  content: ' ';
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
}
.carousel-indicators li {
  width: 30px;
  height: 3px;
  opacity: 1;
  transition: 0.3s ease all;
  cursor: pointer;
}
.carousel-indicators li.active,
.carousel-indicators li.slick-active {
  background: #fff;
}
.carousel-indicators li:hover {
  background: rgba(255,255,255,0.8);
}
.slider-mode-text .slick-slide {
  padding: 1em 4em;
  text-align: center;
}
.slider-mode-text .carousel-indicators {
  bottom: -40px;
}
.slider-mode-text .carousel-control-prev {
  left: -8%;
}
.slider-mode-text .carousel-control-next {
  right: -8%;
}
/* Cards (Portfolio / Products) */
.card .card-thumbnail {
  height: 120px;
  overflow: hidden;
  position: relative;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card .card-thumbnail .card-img-top {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.card .card-body p {
  margin-bottom: 0.5rem;
}
.card .card-body .card-price {
  line-height: 1;
}
.card .card-body .card-price .badge-promo {
  float: right;
}
.card .card-body .card-text {
  font-size: 0.8rem;
  height: 60px;
  overflow: hidden;
}
.list-tax .children {
  padding-left: 24px;
  list-style: none;
}
.list-tax .custom-control {
  display: inline-block;
}
.card {
  box-shadow: none;
  border: none;
}
.list-group-item {
  padding: 0.5rem 1rem;
  font-family: "nunito-sans", sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  border-color: #f0ece6 !important;
  background: #f0ece6;
}
.list-group-item.active {
  color: #231f20;
  background: #edc75e !important;
}
.list-group-item:hover {
  background: #fff;
}
/* Modals */
.modal .modal-content {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
  border-color: #fff !important;
  border-radius: 0;
}
.modal.user-modal .close {
  z-index: 2;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.custom-subnav a {
  font-family: $bold;
  display: block;
  background: #f2f2f2;
  transition: 0.3s ease all;
  padding: 20px;
  font-size: 17px;
  line-height: 1;
  margin-bottom: 1px;
}
.custom-subnav a.is-privacy {
  padding-left: 40px;
  font-family: $regular;
}
.custom-subnav a.active,
.custom-subnav a.active:hover {
  background: #edc75e;
  color: #fff;
}
.custom-subnav a:hover {
  color: #f2f2f2;
}
.page .faq-list li {
  margin: 0px -15px;
  padding: 5px 15px;
  border-bottom: 1px solid #eee;
}
.page .faq-list li:first-child {
  border-top: 1px solid #eee;
}
/* -------------------------------------------------------------------------------
    Style Bootstrap Classes
   ------------------------------------------------------------------------------- */
.btn,
a.btn,
input.btn,
button.btn {
  position: relative;
  border-radius: 0;
  width: 11rem;
  height: 2.25rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-family: "nunito-sans", sans-serif;
  box-shadow: none !important;
}
.btn.btn-outline-primary:before,
a.btn.btn-outline-primary:before,
input.btn.btn-outline-primary:before,
button.btn.btn-outline-primary:before,
.btn.btn-outline-black:before,
a.btn.btn-outline-black:before,
input.btn.btn-outline-black:before,
button.btn.btn-outline-black:before {
  position: absolute;
  display: block;
  content: '';
  width: 2rem;
  height: 1px;
  background: #edc75e;
  top: 50%;
  right: -1.2rem;
  transition: 0.3s ease all;
}
.btn.btn-outline-primary:after,
a.btn.btn-outline-primary:after,
input.btn.btn-outline-primary:after,
button.btn.btn-outline-primary:after,
.btn.btn-outline-black:after,
a.btn.btn-outline-black:after,
input.btn.btn-outline-black:after,
button.btn.btn-outline-black:after {
  position: absolute;
  display: block;
  content: '';
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #edc75e;
  top: 50%;
  transform: translateY(-50%);
  right: -1.2rem;
  margin-right: -2px;
  transition: 0.3s ease all;
}
.btn.btn-outline-primary.sold-out,
a.btn.btn-outline-primary.sold-out,
input.btn.btn-outline-primary.sold-out,
button.btn.btn-outline-primary.sold-out,
.btn.btn-outline-black.sold-out,
a.btn.btn-outline-black.sold-out,
input.btn.btn-outline-black.sold-out,
button.btn.btn-outline-black.sold-out {
  pointer-events: none;
}
.btn.btn-outline-primary.sold-out:before,
a.btn.btn-outline-primary.sold-out:before,
input.btn.btn-outline-primary.sold-out:before,
button.btn.btn-outline-primary.sold-out:before,
.btn.btn-outline-black.sold-out:before,
a.btn.btn-outline-black.sold-out:before,
input.btn.btn-outline-black.sold-out:before,
button.btn.btn-outline-black.sold-out:before,
.btn.btn-outline-primary.sold-out:after,
a.btn.btn-outline-primary.sold-out:after,
input.btn.btn-outline-primary.sold-out:after,
button.btn.btn-outline-primary.sold-out:after,
.btn.btn-outline-black.sold-out:after,
a.btn.btn-outline-black.sold-out:after,
input.btn.btn-outline-black.sold-out:after,
button.btn.btn-outline-black.sold-out:after {
  display: none !important;
}
.btn.btn-outline-primary:hover:before,
a.btn.btn-outline-primary:hover:before,
input.btn.btn-outline-primary:hover:before,
button.btn.btn-outline-primary:hover:before,
.btn.btn-outline-black:hover:before,
a.btn.btn-outline-black:hover:before,
input.btn.btn-outline-black:hover:before,
button.btn.btn-outline-black:hover:before,
.btn.btn-outline-primary:hover:after,
a.btn.btn-outline-primary:hover:after,
input.btn.btn-outline-primary:hover:after,
button.btn.btn-outline-primary:hover:after,
.btn.btn-outline-black:hover:after,
a.btn.btn-outline-black:hover:after,
input.btn.btn-outline-black:hover:after,
button.btn.btn-outline-black:hover:after {
  right: -2rem;
}
.btn.btn-outline-primary:hover,
a.btn.btn-outline-primary:hover,
input.btn.btn-outline-primary:hover,
button.btn.btn-outline-primary:hover {
  color: #000;
}
.btn.btn-outline-black:before,
a.btn.btn-outline-black:before,
input.btn.btn-outline-black:before,
button.btn.btn-outline-black:before {
  background: #000;
}
.btn.btn-outline-black:after,
a.btn.btn-outline-black:after,
input.btn.btn-outline-black:after,
button.btn.btn-outline-black:after {
  border-color: transparent transparent transparent #000;
}
.payfast-logo {
  margin-top: -46px;
}
/* -------------------------------------------------------------------------------
    Navigation
   ------------------------------------------------------------------------------- */
#nav {
  height: 4.15rem;
  position: fixed;
  background-color: #fff;
  width: 100%;
  padding: 0;
  top: 0;
  z-index: 10;
}
#nav #navbar li {
  padding: 0;
  margin: 0;
}
#nav #navbar li a {
  font-size: 0.8rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-transform: uppercase;
  font-family: "nunito-sans", sans-serif;
  color: #505050;
  padding: 0;
  margin: 0;
  letter-spacing: 0.04rem;
}
#nav #navbar li a:hover {
  color: #000;
}
@media only screen and (min-width: 992px) {
  #nav #navbar .nav-left,
  #nav #navbar .nav-right,
  #nav #navbar .nav-right2 {
    z-index: 2;
    height: 100%;
    position: absolute;
  }
  #nav #navbar .nav-left .active a,
  #nav #navbar .nav-right .active a,
  #nav #navbar .nav-right2 .active a {
    color: #edc75e;
  }
  #nav #navbar .nav-left {
    right: 50%;
    margin-right: 3rem;
  }
  #nav #navbar .nav-left a {
    margin-right: 5rem;
  }
  #nav #navbar .nav-right {
    left: 50%;
    margin-left: 5rem;
  }
  #nav #navbar .nav-right a {
    margin-left: 3rem;
  }
  #nav #navbar .nav-right2 {
    left: 50%;
    margin-left: 18.1rem;
    z-index: 1;
  }
  #nav #navbar .nav-right2 a {
    margin-left: 5rem;
  }
  #nav #navbar .nav-right2 .dropdown-menu {
    margin-left: 4rem;
    min-width: 9rem;
    margin-top: -1rem;
  }
  #nav #navbar .nav-right2 .dropdown-menu a {
    margin-left: 1rem;
    text-transform: none;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    text-transform: uppercase;
  }
  #nav #navbar .nav-right2 .dropdown-toggle::after {
    position: absolute;
    right: -0.7rem;
  }
}
#nav .right-nav-btn {
  position: absolute;
  right: 0;
}
#nav .right-nav-btn span {
  font-family: "nunito-sans", sans-serif;
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  border: 1px solid #edc75e;
  font-size: 1rem;
  color: #edc75e;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#nav .right-nav-btn .shop-btn {
  font-family: "nunito-sans", sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  background-color: #edc75e;
  height: 4.15rem;
  width: 4.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4e4e4e;
}
.logo {
  position: absolute;
  width: 100%;
  z-index: 2;
}
.logo .lieben {
  font-family: "baskerville-display-pt", serif;
  line-height: 1;
  color: #231f20;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  letter-spacing: 0.3rem;
}
.logo .lieben:hover {
  color: #000;
}
.logo .wine-spirits {
  position: absolute;
  line-height: 1;
  font-family: "baskerville-display-pt", serif;
  color: #231f20;
  font-size: 1.75rem;
  letter-spacing: 0.175rem;
  left: 50%;
  transform: translateX(-50%);
  top: 1.2rem;
  white-space: nowrap;
}
.logo .diamond-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 3.2rem;
  width: 0;
  height: 0;
  border: 0.17rem solid transparent;
  border-bottom: 0.2rem solid #231f20;
}
.logo .diamond-icon:after {
  content: '';
  position: absolute;
  top: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border: 0.17rem solid transparent;
  border-top: 0.2rem solid #231f20;
}
.logo .uniquely-crafted {
  position: absolute;
  line-height: 1;
  font-family: "nunito-sans", sans-serif;
  font-size: 0.65rem;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
  top: 4.3rem;
  color: #231f20;
  letter-spacing: 0.25rem;
}
#template-legal h4 {
  font-size: 1.5rem;
  text-transform: uppercase;
}
#template-legal p {
  font-family: "nunito-sans", sans-serif;
}
/* -------------------------------------------------------------------------------
    Footer
   ------------------------------------------------------------------------------- */
.footer-space {
  height: 20rem;
  background: #000;
  margin-top: -16rem;
}
#footer {
  background-color: #000;
  padding-top: 5rem;
  padding-bottom: 2.5rem;
/*
	.footer-wrapper {

		>div {
			width: 25%;

			&.contact {
				color: #fff;
				font-size: 18px;
				font-weight: lighter
				text-align: center;
			}

			&.navigation {
				padding-left: 50px;

				li {
					margin-bottom: 40px;

					a {
						font-size: 18px;
						color: #fff;
						font-weight: lighter;
						text-transform: uppercase;
					}
				}
			}

			&.awards {

				img {
					width: 193px;
					margin-bottom: 15px;
				}
			}

			&.social {
				padding-left: 50px;

				ul {

					i {
						color: #fff;
						font-size: 33px;

						&.fa-instagram {
							margin-left: 20px;
							font-size: 36px;
						}
					}
				}

				.legal {
					margin-top: 50px;

					li {
						margin-bottom: 20px;

						a {
							color: #767575;
							font-size: 14px;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
	*/
}
#footer .col {
  padding-left: 30px;
  padding-right: 30px;
}
#footer p,
#footer li {
  font-family: "nunito-sans", sans-serif;
  font-size: 0.9rem;
  color: #fff;
}
#footer a {
  color: #fff;
}
#footer a:hover {
  color: #edc75e;
}
#footer .bottom-margin {
  margin-bottom: 2rem;
}
#footer .awards img {
  margin-bottom: 0.7rem;
}
#footer .footer-primary li {
  margin-bottom: 2rem;
}
#footer .footer-primary li a {
  text-transform: uppercase;
}
#footer .footer-social {
  margin-bottom: 2rem;
}
#footer .footer-social a {
  font-size: 2rem;
  margin-right: 0.5rem;
}
#footer .footer-legal li {
  margin-bottom: 1.1rem;
}
#footer .footer-legal li a {
  color: #767575;
  text-transform: uppercase;
}
#footer .footer-legal li a:hover {
  color: #edc75e;
}
.globe-half-bg {
  background-color: #000;
  height: 700px;
  position: absolute;
  z-index: -1;
  margin-top: 70px;
}
/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */
label {
  font-family: "nunito-sans", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  margin-bottom: 0.2rem;
}
input,
select,
textarea,
.form-control {
  font-family: "nunito-sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.06rem;
  border: 1px solid #8c8c8c;
  border-radius: 0 !important;
}
.small {
  font-family: "nunito-sans", sans-serif;
  font-size: 0.6rem;
  letter-spacing: 0.03rem;
  color: #000;
  text-transform: uppercase;
}
.small a {
  color: #000;
  text-decoration: underline;
}
.custom-control-label,
.custom-control-label a {
  vertical-align: top !important;
}
.custom-control-label.small,
.custom-control-label a.small {
  line-height: 1.6;
}
.custom-select {
  padding: 0.375rem 0.75rem;
  padding-right: 30px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px #fff;
}
form.ajax button.btn-primary[type="submit"].ajax-loading {
  text-indent: -9999px;
  background-image: url("../img/loading-white.svg");
  background-position: center center;
  background-repeat: no-repeat;
}
.form-row {
  margin-bottom: 1rem;
}
.result {
  min-height: 1px;
  font-size: 12px;
}
.result :first-child {
  margin-top: 0;
  padding-top: 0;
}
.success-msg {
  color: #2ea300;
}
.error-msg,
.parsley-errors-list {
  color: #a30000;
  font-size: 12px;
  font-family: "nunito-sans", sans-serif;
}
.parsley-errors-list {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
  display: block;
  overflow: hidden;
}
.parsley-error,
:focus.parsley-error {
  border-color: #a30000;
}
:focus.parsley-error {
  box-shadow: 0 0 0 2px rgba(163,0,0,0.25);
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control.placeholder {
  color: #999;
}
/* -------------------------------------------------------------------------------
    Page Specific
   ------------------------------------------------------------------------------- */
/* Age Gate */
#agegate.hide {
  display: none !important;
}
#agegate .age-overlay {
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(128,128,128,0.72);
}
#agegate .age-content {
  position: fixed;
  z-index: 9999;
  top: 10%;
  left: 50%;
  background: #fff;
  width: 36rem;
  max-height: 80%;
  margin-left: -18rem;
  text-align: center;
  padding: 6rem;
  overflow: scroll;
}
#agegate .age-content h3.h1 {
  font-size: 3.3rem;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1;
  padding: 0;
}
#agegate .age-content h6 {
  margin-bottom: 2rem;
}
#agegate .age-content .age-under {
  color: #b5b5b5;
  font-family: "nunito-sans", sans-serif;
  font-size: 0.8rem;
  text-decoration: underline;
  font-weight: bold;
}
#agegate .age-content .age-msg.age-error {
  color: #a30000;
}
/* Pages with white sub-logo */
#template-contact .logo .wine-spirits,
#template-adventures .logo .wine-spirits,
#template-contact .logo .uniquely-crafted,
#template-adventures .logo .uniquely-crafted {
  color: #fff !important;
}
#template-contact .logo .diamond-icon,
#template-adventures .logo .diamond-icon {
  border-bottom: 0.2rem solid #fff;
}
#template-contact .logo .diamond-icon:after,
#template-adventures .logo .diamond-icon:after {
  border-top: 0.2rem solid #fff;
}
/* Home */
header.bg-home {
  background-image: url("../img/home-header.jpg");
  height: 56vw;
  position: relative;
}
header.bg-home h4.intro-copy {
  position: absolute;
  top: 30%;
}
header.bg-home #home-introduction.slick-slider .slick-list {
  margin: 0;
}
header.bg-home #home-introduction.slick-slider .slick-list .slick-slide {
  padding: 0;
  height: auto;
}
header.bg-home .intro-slider-copy {
  padding: 1rem 1.5rem;
  background: #505050;
  color: #fff;
  margin-top: -1px;
}
header.bg-home .intro-slider-copy p {
  border-left: 1px solid #edc75e;
  margin: 0;
  padding: 0 0 0 1rem;
}
.home-wines {
  position: relative;
  z-index: 2;
  width: 70%;
  min-height: 38rem;
}
.home-wines .bottle {
  position: absolute;
  width: 20rem;
  height: 37rem;
  right: -10rem;
  top: 50%;
}
.home-wines .inner {
  width: 45%;
  margin: 0 auto;
  padding-top: 4rem;
  text-align: right;
}
.home-wines .inner h2 {
  color: #edc75e;
  margin-right: -6px;
}
.home-wines .inner p {
  color: #fff;
}
.home-gins {
  margin: -10rem 5.4rem 0 auto;
  padding: 19rem 6.1rem 0 6.1rem;
  position: relative;
  width: 45%;
  min-height: 56rem;
  background: url("../img/pattern.png") #2e3153;
  background-size: 5rem;
}
.home-gins .bottle {
  left: -32rem;
  bottom: 3.5rem;
  position: absolute;
  width: 35rem;
  height: 32rem;
}
.home-gins h2 {
  color: #edc75e;
}
.home-gins p {
  color: #fff;
}
.home-shop {
  margin-top: 7.5rem;
}
.home-shop .my-margin {
  margin-top: 2.5rem;
  margin-bottom: 4rem;
}
.home-global {
  background-image: url("../img/home-global.jpg");
  height: 60rem;
  margin-top: -2rem;
  margin-right: 4rem;
}
.home-global h3 {
  margin-top: 9.5rem;
}
.home-global .home-global-slider-container {
  margin-top: 3rem;
  margin-right: 8rem;
  position: relative;
}
.home-global .home-global-slider-container .slick-slider {
  overflow: hidden;
}
.home-global .home-global-slider-container .home-global-arrows {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: 10rem;
  transform: translate(100%, -100%);
  flex-direction: column-reverse;
}
.home-global .home-global-slider-container .home-global-arrows .slick-arrow-prev {
  margin-top: -1px;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-list {
  margin: 0 -10px;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide {
  height: 30rem;
  padding: 0 10px;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide > div {
  position: relative;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-bg {
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 20rem;
  transition: 0.5s ease all;
/*
					&:hover {
						height: 30rem;
					}
					*/
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-bg:before,
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-bg:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 80%;
  display: block;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-bg:before {
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
  top: 0;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-bg:after {
  background: linear-gradient(0deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
  bottom: 0;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-opened .slide-bg {
  height: 30rem;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-opened .slide-text {
  margin-top: 5rem;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-opened .slide-text p {
  opacity: 1;
  margin-top: 0;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-opened .open-slide:before {
  transform: rotate(0deg);
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .open-slide {
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: 5rem;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .open-slide:before,
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .open-slide:after {
  position: absolute;
  content: '';
  width: 3.5rem;
  height: 2px;
  background: #fff;
  top: 50%;
  margin-top: -1px;
  transition: 0.3s ease all;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .open-slide:before {
  transform: rotate(90deg);
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-text {
  transition: 0.7s ease all;
  position: absolute;
  z-index: 2;
  margin-left: 2rem;
  width: 65%;
  top: 50%;
  margin-top: 6.5rem;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-text h5,
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-text p {
  color: #fff;
}
.home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-text p {
  transition: 0.5s ease all;
  opacity: 0;
  margin-top: -1rem;
  font-family: "nunito-sans", sans-serif;
  font-size: 0.65rem;
}
/* Wines & Gins */
.gin-logo {
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  margin-top: 3rem;
  background: url("../img/gin_logo.svg") no-repeat center center;
  background-size: contain;
}
.wine-logo {
  width: 5.8rem;
  height: 2.5rem;
  margin: 0 auto;
  background: url("../img/wine_logo.svg") no-repeat center center;
  background-size: contain;
}
.wine-origami {
  width: 33rem;
  height: 6.25rem;
  margin: 0 auto;
  margin-top: 4rem;
  background: url("../img/wine_origami.svg") no-repeat center center;
  background-size: contain;
}
.wines-gins-intro h6 {
  font-size: 1.8rem;
  letter-spacing: 0.09rem;
  line-height: 1.3;
}
.wines-gins-intro-2 h6 {
  font-size: 1.6rem;
  letter-spacing: 0.1333rem;
  line-height: 1.4;
}
.gin-intro .wines-gins-intro-2 {
  background-size: auto 100%;
  background-position: right center;
  background-color: #0c0a0b;
}
.price {
  font-family: "baskerville-urw", serif;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  margin-top: -1rem;
}
.price span {
  font-size: 3.2rem;
}
.wines-gins #wines {
  background: #595959;
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
}
.wines-gins #wines #roussanne .wine-card {
  height: 38rem;
}
.wines-gins #wines .wine-card {
  position: relative;
  width: 42rem;
  margin-left: 6.5rem;
  padding: 3.25rem 8.5rem 3.25rem 8.25rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
  height: 29rem;
}
.wines-gins #wines .wine-card .wine-bottle {
  position: absolute;
  width: 18rem;
  height: 34rem;
  left: 0;
  top: -2rem;
  transform: translateX(-50%);
}
.wines-gins #wines .wine-card .wine-image {
  position: absolute;
  width: 19rem;
  height: 20rem;
  right: -13rem;
  top: 10%;
  box-shadow: 0px 0px 2rem rgba(0,0,0,0.161);
}
.wines-gins #wines .wine-card.wine-card-right {
  margin-left: inherit;
  margin-right: 6.5rem;
  right: 0;
  text-align: right;
}
.wines-gins #wines .wine-card.wine-card-right .wine-bottle {
  left: inherit;
  right: 0;
  transform: translateX(50%);
}
.wines-gins #wines .wine-card.wine-card-right .wine-image {
  right: inherit;
  left: -13rem;
}
.wines-gins .wines-bg {
  background-image: url("../img/wines_bg.jpg");
  min-height: 74rem;
  margin-top: -20rem;
  margin-bottom: -20rem;
}
.wines-gins #gins {
  margin-bottom: 18rem;
}
.wines-gins #gins .bg-gin {
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 4rem;
  margin-bottom: 0rem;
  min-height: 56rem;
  position: relative;
}
.wines-gins #gins .bg-gin.gin-card-left {
  background-position: right center;
}
.wines-gins #gins .bg-gin.gin-card-right {
  background-position: left center;
}
.wines-gins #gins .bg-gin.bg-tinker {
  background-image: url("../img/gins/bg/tinker.jpg");
}
.wines-gins #gins .bg-gin.bg-tailor {
  background-image: url("../img/gins/bg/tailor.jpg");
}
.wines-gins #gins .bg-gin.bg-soldier {
  background-image: url("../img/gins/bg/soldier.jpg");
}
.wines-gins #gins .bg-gin.bg-sailor {
  background-image: url("../img/gins/bg/sailor.jpg");
}
.wines-gins #gins .bg-gin.bg-spy {
  background-image: url("../img/gins/bg/spy.jpg");
}
.wines-gins #gins h3 {
  text-align: center;
  color: #d7682d;
  margin-bottom: 0;
  padding-bottom: 0;
}
.wines-gins #gins h4 {
  text-align: center;
  color: #abaaa4;
  font-size: 1.8rem;
  padding-top: 0;
  margin-top: 0;
}
.wines-gins #gins .gin-pattern {
  position: absolute;
  width: 50%;
  height: 45rem;
  top: 8rem;
  text-align: center;
  padding: 0 10rem;
}
.wines-gins #gins .gin-pattern .gin-bottle {
  position: absolute;
  pointer-events: none;
  width: 26rem;
  height: 40rem;
  right: 0;
  top: 2rem;
  transform: translateX(50%);
}
.wines-gins #gins .gin-pattern p {
  color: #000;
}
.wines-gins #gins .gin-pattern .gin-taste {
  display: none;
}
.wines-gins #gins .gin-card-right .gin-pattern {
  right: 0;
}
.wines-gins #gins .gin-card-right .gin-pattern .gin-bottle {
  left: 0;
  transform: translateX(-50%);
}
.wines-gins #gins .gin-nav {
  margin-bottom: 2rem;
}
.wines-gins #gins .gin-nav a {
  color: rgba(0,0,0,0.5);
  font-family: "nunito-sans", sans-serif;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.wines-gins #gins .gin-nav a.active {
  color: #000;
  border-bottom: 2px solid #6c6e71;
}
.wines-gins #gins .pattern-spy .gin-nav a {
  color: rgba(255,255,255,0.5);
}
.wines-gins #gins .pattern-spy .gin-nav a.active {
  color: #fff;
  border-bottom: 2px solid #a6a8b6;
}
.wines-gins #gins .price {
  margin-top: 0;
  margin-bottom: 1rem;
}
#template-gins {
  background: #e8e1d7;
}
/* Adventures */
.adventures-links a {
  font-family: "nunito-sans", sans-serif;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 0.3rem;
}
.adventures-links a.active:after {
  content: '';
  width: 2rem;
  height: 1px;
  background: #edc75e;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
/* Contact */
#template-contact footer {
  display: none;
}
#template-contact .contact {
  color: #fff;
  padding-top: 7rem;
  padding-bottom: 4rem;
  font-family: "nunito-sans", sans-serif;
}
#template-contact .contact .bg {
  background-position: bottom right;
  min-height: 36vw;
}
#template-contact .contact .options li {
  line-height: 1.3;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: "nunito-sans", sans-serif;
}
#template-contact .contact .options li a {
  font-family: "nunito-sans", sans-serif;
  font-size: 1.5rem;
  color: #fff;
  letter-spacing: 0.0925rem;
}
#template-contact .contact .options li a:hover {
  color: #edc75e;
}
#template-contact .contact .shop-btn {
  font-family: "nunito-sans", sans-serif;
  width: 12rem;
  height: 2.4rem;
  background: #edc75e;
  color: #000 !important;
  font-size: 1.4rem;
  line-height: 1.4rem;
}
#template-contact .wine-info,
#template-contact .gin-info {
  padding-bottom: none;
  border-bottom: none;
}
#template-contact .wine-info p a,
#template-contact .gin-info p a {
  color: #fff;
}
#template-contact .social-info a:hover {
  color: #fff;
}
.wine-info,
.gin-info {
  border-bottom: 1px solid #707070;
  padding-bottom: 0.5rem;
}
.wine-info h6,
.gin-info h6 {
  font-family: "nunito-sans", sans-serif;
  font-size: 0.83rem;
  letter-spacing: 0.06rem;
  margin-bottom: 1rem;
}
.wine-info p,
.gin-info p {
  font-family: "nunito-sans", sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0.3rem;
}
.wine-info p a,
.gin-info p a {
  color: #212529;
}
.gin-info {
  margin-top: 1.2rem;
}
.social-info {
  margin-top: 1.2rem;
  font-family: "nunito-sans", sans-serif;
}
.social-info a {
  color: #4a4a4a;
  text-transform: uppercase;
}
.social-info li {
  margin-top: 0.3rem;
}
/* Our Story */
.story-split > div {
  min-height: calc(100vh - 4.15rem);
  width: 50%;
  float: left;
  position: relative;
  background-position: center center;
}
.story-split > div h3 {
  position: absolute;
  bottom: 8vh;
  left: 50%;
  transform: translateX(-50%);
}
.split {
  position: relative;
}
.split > div {
  width: 50%;
  float: left;
  position: relative;
  background-position: bottom center;
}
.story-couple {
  height: 100vh;
  padding: 0 10vw;
}
.story-couple h4 {
  font-size: 1.25rem;
}
.story-alwyn {
  padding: 11rem 0 7.5rem 0;
}
.story-alwyn .story-alwyn-left {
  float: left;
  width: 57%;
  padding-left: 10vw;
  padding-right: 10vw;
}
.story-alwyn .story-alwyn-right {
  float: left;
  width: 43%;
}
.story-alwyn .story-alwyn-right img {
  width: 100%;
  height: auto;
}
.story-adventures {
  background: #207a78;
  padding: 2rem;
}
.story-beulah {
  width: 78%;
  margin: 0 auto;
}
.story-beulah .story-beulah-left {
  float: left;
  width: 36%;
}
.story-beulah .story-beulah-left img {
  width: 100%;
  height: auto;
}
.story-beulah .story-beulah-right {
  float: left;
  width: 64%;
  padding-left: 5.5vw;
  padding-right: 5.5vw;
}
/* Shop */
.pattern-tinker,
.pattern-tailor,
.pattern-soldier,
.pattern-sailor,
.pattern-spy {
  background-size: cover;
}
.pattern-tinker {
  background-color: #d6dbe3;
  background-image: url("../img/gins/patterns/tinker.png");
}
.pattern-tailor {
  background-color: #dbd0c7;
  background-image: url("../img/gins/patterns/tailor.png");
}
.pattern-soldier {
  background-color: #f4c9c0;
  background-image: url("../img/gins/patterns/soldier.png");
}
.pattern-sailor {
  background-color: #b8cbcf;
  background-image: url("../img/gins/patterns/sailor.png");
}
.pattern-spy {
  background-color: #37365e;
  background-image: url("../img/gins/patterns/spy.png");
}
body.shop .shop-intro {
  min-height: 26rem;
  position: relative;
}
body.shop .shop-intro h3 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.shop .shop-card {
  min-height: 34rem;
  border: 5px solid #f0ece6;
  text-align: center;
  position: relative;
  padding-top: 20rem;
}
body.shop .shop-card .btn {
  width: 7.5rem;
}
body.shop .shop-card .btn:first-child {
  margin-right: 0.5rem;
}
body.shop .shop-card h2:before,
body.shop .shop-card h2:after {
  display: none;
}
body.shop .shop-card h3 {
  font-size: 1.1rem;
}
body.shop .shop-card .wine-bottle,
body.shop .shop-card .gin-bottle {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 12.5rem;
  height: 23rem;
  margin-top: -1rem;
}
body.shop .shop-card .gin-bottle {
  height: 21rem;
  top: 2rem;
}
/* Products */
.products-results:before {
  transition: opacity 0.5s ease;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: url("../img/loading.svg") no-repeat center center rgba(255,255,255,0.7);
  opacity: 0;
  pointer-events: none;
}
.products-results.loading:before {
  opacity: 1;
}
.current-cat {
  font-weight: bold;
}
.price-new {
  font-weight: bold;
}
.price-old {
  text-decoration: line-through;
}
.product-price {
  line-height: 1;
}
.container-product .breadcrumb {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  background: transparent;
}
/* Checkout */
#checkout .price {
  color: #000 !important;
  font-size: 1rem;
}
#checkout .price span {
  font-size: 2rem;
}
#checkout span.h3 {
  color: #595959;
  font-size: 1.6rem;
  padding-bottom: 0;
}
#checkout h5.hi-name {
  text-transform: none;
}
#checkout .checkout-btn-group .btn {
  border: 2px solid #fff;
  border-radius: 0;
}
#checkout .checkout-btn-group .btn.btn-secondary {
  background: #efdaa1;
}
#checkout .form-footer {
  display: none;
}
#checkout .checkout-step {
  display: none;
}
#checkout .checkout-step.active {
  display: block;
}
#checkout table .cart-item td {
  padding-left: 0;
}
#checkout table .cart-item td:last-child {
  padding-right: 0.3rem;
  white-space: nowrap;
}
#checkout .cart-item-qty-group {
  width: 6rem;
}
#checkout .cart-item-qty-group .btn-cart-update {
  border: none;
  background: none;
  font-size: 2rem;
}
#checkout .cart-item-qty-group .input-group-prepend .btn-cart-update {
  transform: translate(-0.4rem, -0.3rem);
}
#checkout .cart-item-qty-group .cart-item-qty {
  font-family: "nunito-sans", sans-serif;
  border: none;
  background: #edc75e;
  border-radius: 1rem !important;
  width: 2rem;
  height: 2rem;
  padding: 0;
  line-height: 2rem;
  margin-top: 0.3rem;
}
#checkout .product-thumb {
  max-width: inherit;
  height: 6rem;
  width: auto;
}
#checkout .product-thumb-td {
  width: 3rem;
}
#checkout .remove-btn {
  color: #b2a99a;
  font-family: "nunito-sans", sans-serif;
  letter-spacing: 0.05rem;
  font-size: 0.7rem;
}
#checkout .btn-success {
  height: 3.6rem;
  line-height: 3.5rem;
  font-size: 1.4rem;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
  border: none;
  width: 10.5rem;
}
#checkout .card-header,
#checkout .card-body {
  padding-left: 0.2rem;
}
#checkout .cart-heading {
  font-family: "baskerville-urw", serif;
  font-size: 1.2rem;
  color: inherit;
}
#checkout #delivery_option,
#checkout .billing_address,
#checkout .delivery_address {
  font-family: "nunito-sans", sans-serif;
  font-size: 0.8rem;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background: #edc75e;
}
.success-msg {
  font-family: "nunito-sans", sans-serif;
  font-size: 1rem;
  color: #505050;
}
.checkout-cart .cart-item-qty {
  padding: 0;
}
.cart-count {
  margin-left: 1px;
  margin-right: 1px;
}
.free-shipping {
  position: absolute;
  left: 13.4rem;
  margin-top: -3.3rem;
}
.free-shipping .free-shipping-1 {
  font-family: "nunito-sans", sans-serif;
  text-transform: uppercase;
  font-size: 0.85rem;
  color: #505050;
  margin: 0;
}
.free-shipping .free-shipping-2 {
  color: #edc75e;
  font-size: 1.65rem;
}
.my-orders {
  font-family: "nunito-sans", sans-serif;
}
.my-orders th {
  font-family: "nunito-sans", sans-serif;
  font-weight: bold;
}
/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
html {
  font-size: 24px;
}
@media only screen and (max-width: 1919px) {
  html {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1799px) {
  html {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1649px) {
  html {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1499px) {
  html {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1399px) {
  html {
    font-size: 17px;
  }
}
@media only screen and (max-width: 1199px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1199px) {
  html {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  html {
    font-size: 13px;
  }
}
@media only screen and (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}
@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}
@media only screen and (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
@media only screen and (min-width: 1700px) {
  .container {
    max-width: 1640px;
  }
}
@media only screen and (min-width: 1800px) {
  .container {
    max-width: 1740px;
  }
}
@media only screen and (min-width: 1920px) {
  .container {
    max-width: 1860px;
  }
}
@media only screen and (max-width: 767px) {
  header.bg-home h4.intro-copy {
    position: relative;
    top: inherit;
  }
  .home-wines {
    margin-top: 3rem;
    width: 100%;
    padding-bottom: 10rem;
    background-position: bottom center;
  }
  .home-wines .bottle {
    display: none;
  }
  .home-wines .inner {
    width: 70%;
    padding-bottom: 5rem;
  }
  .home-gins {
    margin: 0;
    width: 100%;
    padding: 6rem;
    min-height: inherit;
  }
  .home-gins .bottle {
    display: none;
  }
  .home-global {
    margin-right: 0;
  }
  .home-global .home-global-slider-container #home-global.slick-slider .slick-slide .slide-text p {
    font-size: 0.8rem;
  }
  .footer-primary,
  .footer-legal {
    display: none;
  }
  #footer .awards {
    display: none;
  }
  .footer-social-icons {
    text-align: center;
    margin-top: 3rem;
  }
  .split > div {
    width: 100%;
    float: none;
  }
  .story-alwyn .story-alwyn-left,
  .story-alwyn .story-alwyn-right {
    width: 100%;
    float: none;
  }
  .no-full-mobile {
    padding-top: 5rem;
    padding-bottom: 5rem;
    min-height: auto;
    height: auto;
  }
  .story-beulah {
    width: 100%;
    margin: 0;
    margin-top: -5rem;
  }
  .story-beulah .story-beulah-left,
  .story-beulah .story-beulah-right {
    width: 100%;
  }
  #template-story .clearfix.py-6.bg-white {
    display: none;
  }
  .contact .shop-btn {
    position: absolute;
    top: 0;
    right: 1rem;
  }
  .contact .footer-social {
    display: none;
  }
  .wines-gins #wines {
    padding: 0;
  }
  .wines-gins #wines .container {
    padding: 0;
  }
  .wines-gins #wines .wine-card {
    margin: 0;
    width: 100%;
    height: auto;
    padding: 3.25rem 3.25rem 3.25rem 12rem;
  }
  .wines-gins #wines .wine-card .wine-bottle {
    transform: none;
    pointer-events: none;
    top: 0.5rem;
    left: -3.5rem;
  }
  .wines-gins #wines .wine-card .wine-image {
    display: none;
  }
  .wines-gins #wines .wine-card.wine-card-right {
    padding: 3.25rem 12rem 3.25rem 3.25rem;
  }
  .wines-gins #wines .wine-card.wine-card-right .wine-bottle {
    transform: none;
    right: -3.5rem;
  }
  .wines-gins .wines-gins-intro-2 {
    background-image: none !important;
    background-color: #000;
  }
  .wines-gins #gins .gin-card {
    background-position-y: top !important;
    min-height: auto;
  }
  .wines-gins #gins .gin-card .gin-pattern {
    position: relative;
    top: inherit;
    height: auto;
    width: 100%;
    margin-top: 40rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .wines-gins #gins .gin-card .gin-pattern .gin-bottle {
    right: inherit;
    top: -40rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 991px) {
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 1.2rem;
  }
  #menu-account .dropdown-toggle {
    display: none !important;
  }
  #nav #navbar {
    margin-top: -38px;
  }
  #nav .navbar-toggler {
    margin-left: 15px;
    border: none;
    border-radius: 0;
    background: #edc75e;
    z-index: 1000;
    margin-top: 12px;
  }
  #nav .collapsed {
    margin-top: 2px !important;
  }
  #nav .nav-left {
    margin-top: 4rem;
  }
  #nav .nav-left,
  #nav .nav-right,
  #nav .nav-right2 {
    background: #fff;
    padding-left: 1rem;
  }
  #nav .nav-left a,
  #nav .nav-right a,
  #nav .nav-right2 a {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }
  #nav .nav-right {
    padding-bottom: 1rem;
  }
  #nav .dropdown-menu {
    display: block !important;
    border: none;
    box-shadow: none;
  }
  header.bg-home {
    height: inherit;
    padding-top: 6rem;
  }
}
@media only screen and (max-width: 992px) {
  .tablet-hide {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .mobi-hide {
    display: none !important;
  }
  .wine-origami {
    width: auto;
  }
  .frontsmall {
    font-size: 30px;
  }
  .wine-card {
    overflow-x: hidden !important;
  }
  .wines-gins-intro-2 .container {
    overflow-x: hidden;
  }
  .btn-group button {
    overflow: hidden;
  }
}
@media only screen and (max-width: 320px) {
  #nav .right-nav-btn span {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 10px;
  }
  .wine-bottle {
    overflow-x: hidden !important;
  }
  #template-wines .wine-card .text-primary,
  #template-wines .text-black {
    font-size: 23px;
  }
}
@media (min-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #menu-main-navigation-left,
  #menu-main-navigation-right,
  #menu-account,
  .cart-count {
    margin-top: -30px;
  }
  .shop-btn {
    margin-top: -35px;
  }
  .btn {
    padding-top: 8px;
  }
  .btn-checkout,
  .btn-gateway {
    padding-top: 5px !important;
  }
  .cart-count {
    padding-top: 3px;
  }
  #template-wines .wine-logo,
  #template-wines .gin-logo {
    margin-top: 126px;
  }
  #template-wines #chenin-blanc .description,
  #template-wines #roussanne .description,
  #template-wines #pinot-noir .description {
    margin-bottom: 130px;
  }
  #template-wines .red-wine-intro {
    padding-top: 450px;
  }
  #template-gins .gin-logo {
    margin-top: 126px;
  }
  #template-gins .wines-gins-intro-2 .col-6 {
    padding-top: 130px;
  }
  #template-adventures .col-12 .text-primary,
  #template-adventures .col-8 .text-primary {
    margin-top: 200px;
  }
  #template-adventures .mobi-hide .img-fluid {
    height: 850px;
  }
  #template-contact .options .shop-btn {
    margin-top: 10px !important;
  }
  #template-contact .options .list-unstyled {
    margin-top: -20px;
  }
  input {
    padding-bottom: 10px !important;
  }
}
